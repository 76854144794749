<template>
  <div class="binding_detail">
    <div class="detail_card">
      <div class="item">
        <div class="tit">设备编号：</div>
        <div class="r_content">{{ info.id || "无" }}</div>
      </div>
      <div class="item">
        <div class="tit">位置名称：</div>
        <div class="r_content">{{ info.location || "无" }}</div>
      </div>
      <div class="item">
        <div class="tit">详细地址：</div>
        <div class="r_content">{{ info.address_detail || "无" }}</div>
      </div>
      <div class="item">
        <div class="tit">安装时间：</div>
        <div class="r_content">{{ info.created_at || "无" }}</div>
      </div>
    </div>

    <div class="login_btn">
      <van-button class="btn" type="info" @click="updata">
        更新绑定
      </van-button>
      <p class="txt">*确认更新绑定后，该充电桩将重新与扫描的二维码绑定</p>
    </div>
  </div>
</template>

<script>
import { getData, postData } from "@/api/user";
import { Dialog } from "vant";

export default {
  data() {
    return {
      info: {},
      qr_id: "",
      charger_id: "",
    };
  },
  created() {
    let query = this.$route.query;
    console.log("query>>>>>", this.$route);
    console.log("query>>>>>", query);

    if (query) {
      this.qr_id = query.qr_id;
      this.charger_id = query.charger_id;

      this.get_info(query.charger_id);
    }
  },
  methods: {
    get_info(charger_id) {
      getData({
        url: "api/maintain/index/qrcode_info",
        data: {
          charger_id,
        },
        is_admin: true,
      }).then((res) => {
        console.log(res);
        let data = res.data;
        if (data.code === 200) {
          this.info = data.data.data;
        }
      });
    },
    updata() {
      Dialog.confirm({
        title: "",
        confirmButtonColor: "#3DC48D",
        // message: "确认更新？",
        message: `<div style="font-size:18px; font-weight:700; color:#333;">确认更新？</div>`,
      })
        .then(() => {
          // on confirm
          postData({
            url: `api/maintain/index/update_qrcode`,
            data: {
              qr_id: this.qr_id,
              charger_id: this.charger_id,
            },
            is_admin: true,
          }).then((res) => {
            let data = res.data;
            if (data.code === 200) {
              this.$toast(data.data.msg);
              this.$router.push({ name: "admin_binding_list" });
            } else if (data.code === 422) {
              this.$toast(data.data.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="less" scoped>
.binding_detail {
  position: relative;
  padding: 20px 12px;
  height: 100vh;

  .detail_card {
    padding: 20px 16px;
    background: #ffffff;
    border-radius: 15px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      line-height: 1;

      .tit {
        margin-right: 32px;
        font-size: 14px;
        color: #666666;
        flex-shrink: 0;
      }

      .r_content {
        font-size: 15px;
        color: #333333;
        text-align: right;
        line-height: 20px;
      }
    }

    .item:last-child {
      margin-bottom: 0;
    }
  }

  .login_btn {
    position: absolute;
    left: 0;
    bottom: calc(20px + constant(safe-area-inset-bottom));
    bottom: calc(20px + env(safe-area-inset-bottom));
    padding: 0 20px;
    width: 100vw;

    .btn {
      // width: 351px;
      width: 100%;
      height: 44px;
      color: #fff;
      font-size: 18px;
      background: #3dc48d;
      border-radius: 22px;
      border: none;
    }

    .txt {
      margin-top: 18px;
      font-size: 12px;
      line-height: 1;
      color: #999999;
      text-align: center;
    }
  }
}
</style>